import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import PageTitle from "../components/page-title"
import { FiMapPin, FiPhoneCall } from "react-icons/fi"
import { FaRegEnvelopeOpen } from "react-icons/fa"
import ContactForm from "../components/contact/contact"
import MyFancyComponent from "../components/maps"

const Contact = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Contact"
        keywords="ribsusabbq, ribs usa, ribs usa bbq, ribs, usa, bbq, best bbq near me, baby back ribs, beef ribs near me, best beef ribs near me, bbq chicken near me"
      />
      <PageTitle title="Contact Us" subtitle="How can we help?" />
      <section id="contact" className="contact">
        <div className="container">
          <div className="row">
            <div
              data-sal="slide-up"
              data-sal-delay="200"
              data-sal-easing="ease-out-back"
              className="col-lg-6"
            >
              <div className="info-box mb-4">
                <FiMapPin size={20} />
                <h3 className="sans">Our Address</h3>
                <p className="sans">{data.site.siteMetadata.address}</p>
              </div>
            </div>

            <div
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease-out-back"
              className="col-lg-3 col-md-6"
            >
              <div className="info-box  mb-4">
                <FaRegEnvelopeOpen size={19} />
                <h3 className="sans">Email Us</h3>
                <a
                  className="con-hover"
                  href={`mailto:${data.site.siteMetadata.email}`}
                >
                  <p className="sans">{data.site.siteMetadata.email}</p>
                </a>
              </div>
            </div>

            <div
              data-sal="slide-up"
              data-sal-delay="400"
              data-sal-easing="ease-out-back"
              className="col-lg-3 col-md-6"
            >
              <div className="info-box  mb-4">
                <FiPhoneCall size={20} />
                <h3 className="sans">Call Us</h3>
                <a
                  className="con-hover"
                  href={`tel:${data.site.siteMetadata.tel}`}
                >
                  <p className="sans">{data.site.siteMetadata.tel}</p>
                </a>
              </div>
            </div>
          </div>

          <div className="row">
            <div
              data-sal="slide-up"
              data-sal-delay="400"
              data-sal-easing="ease-out-back"
              className="col-lg-6 "
            >
              <div id="googleMap">
                <MyFancyComponent />
              </div>
            </div>

            <div
              data-sal="slide-up"
              data-sal-delay="500"
              data-sal-easing="ease-out-back"
              className="col-lg-6"
            >
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Contact

export const query = graphql`
  {
    site {
      siteMetadata {
        tel
        email
        address
      }
    }
  }
`
