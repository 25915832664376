import React from "react"

const PageTitle = props => (
  <div className="page-title text-center">
    <h3>{props.title}</h3>
    <h6>{props.subtitle}</h6>
  </div>
)

export default PageTitle
