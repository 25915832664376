/* eslint-disable */
import React, { Component } from "react"

class ContactForm extends Component {
  constructor() {
    super()
    this.state = {
      name: "",
      email: "",
      subject: "",
      message: "",
    }
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value })

  handleSubmit = e => {
    const body = {
      name: this.state.name,
      email: this.state.email,
      subject: this.state.subject,
      message: this.state.message,
    }

    const endpoint = `https://b7ln6v3wr0.execute-api.us-east-1.amazonaws.com/prod/ribsusa-contact-form-logic`

    const endpointRequest = new Request(endpoint, {
      method: "POST",
      body: JSON.stringify(body),
    })
    fetch(endpointRequest)
      .then(response => {
        console.log(response.status)
        if (response.status === 200) {
          this.setState({
            name: "",
            email: "",
            message: "",
            subject: "",
          })
          document.getElementById("success").innerHTML =
            "Thanks, We received your message."
        } else {
          document.getElementById("wrong").innerHTML = "Something wrong."
        }
      })
      .catch(error => {
        console.log("error")
      })

    e.preventDefault()
  }

  render() {
    let { name, email, subject, message } = this.state
    return (
      <form className="email-form" onSubmit={this.handleSubmit}>
        <div className="form-row">
          <div className="col form-group">
            <input
              type="text"
              name="name"
              className="form-control"
              placeholder="Your Name"
              onChange={this.handleChange}
              value={name}
              required
            />
          </div>
          <div className="col form-group">
            <input
              type="email"
              className="form-control"
              name="email"
              placeholder="Your Email"
              onChange={this.handleChange}
              value={email}
              required
            />
          </div>
        </div>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            name="subject"
            placeholder="Subject"
            onChange={this.handleChange}
            value={subject}
            required
          />
        </div>
        <div className="form-group">
          <textarea
            className="form-control"
            name="message"
            rows="5"
            placeholder="Message"
            onChange={this.handleChange}
            value={message}
            required
          ></textarea>
        </div>
        <div className="text-center">
          <button type="submit">
            <a className="btn my-button sans">
              <span className="myover">Send Message</span>
            </a>
          </button>
        </div>
        <div className="abel" id="success"></div>
        <div className="abel" id="wrong"></div>
      </form>
    )
  }
}

export default ContactForm
